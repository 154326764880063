<template>
    <b-table small :fields="fields_evaluation" :items="evaluation" responsive="lg" tbody-tr-class="size-14 evaluation-tab" show-empty>

		<template #cell(no)="data">
			<span class="text-left d-block black-im">{{ data.index + 1 }}</span>
		</template>
		<template #cell(presenter_name)="data">
			<template v-if="data.item.presenter_name != null">
				<span class="text-center d-block">{{ data.value.eoi_name }}</span>
			</template>
			<template v-else><span class="text-center d-block">-</span></template>
		</template>
		<template #cell(actions)="data">
			<template v-if="data.item.status == 1">

				<b-link @click="evaluation_detail(data.item.id, data.item.template)" target="_blank">
					<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.501 16H2.50098C1.39641 16 0.500977 15.1046 0.500977 14V3C0.500977 1.89543 1.39641 1 2.50098 1H6.50098V3H2.50098V14H13.501V10H15.501V14C15.501 15.1046 14.6055 16 13.501 16ZM8.20098 9.707L6.79098 8.293L13.084 2H9.50098V0H16.501V7H14.501V3.415L8.20098 9.707Z" fill="#442E2E"/>
					</svg>
				</b-link>
			</template>
			<template v-else>
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.501 16H2.50098C1.39641 16 0.500977 15.1046 0.500977 14V3C0.500977 1.89543 1.39641 1 2.50098 1H6.50098V3H2.50098V14H13.501V10H15.501V14C15.501 15.1046 14.6055 16 13.501 16ZM8.20098 9.707L6.79098 8.293L13.084 2H9.50098V0H16.501V7H14.501V3.415L8.20098 9.707Z" fill="#b1b1b1"/>
				</svg>
			</template>
		</template>	
		<template #cell(date)="data">
        	<span class="text-center d-block">{{ data.item.date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
      	</template>
		<template #cell()="data">
			{{ data.value }}
		</template>
		<template #empty="scope">
	      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
	    </template>

	</b-table>
</template>

<script>

	export default {
	  	data() {
		    return {
                fields_evaluation: [
			        { key: 'no', label: 'No.' },
			        { key: 'title', label: 'Session Title' },
			        { key: 'presenter_name', label: 'Presenter' },			    
			        { key: 'date', label: 'Date' },			            
			        { key: 'status_submit_form', label: 'Status' },
			        { key: 'actions', label: 'Actions' },
			    ],
		    	evaluation: [],
            }
		},
		created(){
			this.fetchList()
		},
		methods: {
			fetchList(){
	            this.$store
	            .dispatch('activities/fetchEva', {
	                activity_id: this.$route.params.id,
	            })
	            .then(response => {
	                this.evaluation = response.data.data
	                // console.log('Part Evaluation: ', response.data.data)
	            })
	            .catch((error) => {
	              	console.log(error)
	            })
	        }, 
			evaluation_detail(id, template_id){
				let routeData = this.$router.resolve({ path: `/participant/my-activities/${this.$route.params.id}/evaluation/${id}`, query: {template: template_id }});
				window.open(routeData.href, '_blank');
			}
		}
	};	

</script>

<style lang="scss">
</style>