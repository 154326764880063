<template>
    <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 document" show-empty>

        <template #cell(no)="data">
            {{ data.index + 1 }}
        </template>
        <template #cell(attach_file_title)="data">
            <span class="maxTwoRow">{{ data.item.attach_file_title }}</span>
        </template>
        <template #cell(attach_content_type)="data">
            <span class="text-center maxThreeRow">{{ data.item.attach_content_type }}</span>
        </template>
        <template #cell(updated_at)="data">
            <span class="text-center d-block">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
        </template>
        <template #cell(attach_file_name)="data">
            <div class="text-center d-block black-ico cursor-pointer" @click="downloadAction(data.item.attach_file_name, data.item.attach_file_title); ">
                <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    />
            </div>	
        </template>
        <template #cell()="data">
            {{ data.value }}
        </template>
        <template #empty="scope">
            <h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
        </template>

    </b-table>
</template>

<script>

	export default {
	  	components: {},
	  	props: {
            label: {
                type: String,
                required: false,
                default: () => ''
            }
        },
	  	data() {
		    return {
                fields: [
                    { key: 'no', label: 'No.' },
                    { key: 'attach_file_title', label: 'Document Name' },
                    { key: 'attach_content_type', label: 'Remarks' },
                    { key: 'updated_at', label: 'Uploaded Date' },
                    { key: 'attach_file_name', label: 'Actions' },
                ],
		    	items: [],
            }
		},
		created(){
			this.fetchDocument();
		},
		methods: {
            downloadAction(path, title){
		        const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
                axios({
                    method: 'get',
                    url: url,
                    responseType: 'blob',
                })
                .then(response => {             
                    this.forceFileDownload(response, title)            
                })
                .catch(() => console.log('error occured'))
		    },
            forceFileDownload(response, namFile){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', namFile)
                document.body.appendChild(link)
                link.click()
            },
			fetchDocument(){
                this.$store.dispatch('activities/fetchDocument', {
                    id: this.$route.params.id,
					filter: {
						"list_search": [
						    {
						      "attachable_id": this.$route.params.id,
						      "attachable_type": "activity"
						    }
						  ]
					} 
                })
                .then(response => {
                    this.items = response.data.data;
                    // console.log('Document: ', response.data.data);
                })
                .catch((error) => {
                    console.log(error)
                })
            }
		}
	};	

</script>

<style lang="scss">
	.document-tab thead tr th:nth-of-type(2){
		text-align: left !important
	}
</style>