<template>
    <b-form @submit.prevent class="frm-basic size-16">
        <b-row>
            <b-col cols="12">
                <b-form-group
                    label="Activity Name"
                    label-for="h-actiivity"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-input
                        id="h-actiivity"
                        v-model="form.name"
                        readonly
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    label="Country"
                    label-for="h-country"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-input
                        id="h-country"
                        v-model="form.country_name"
                        readonly
                    />
                </b-form-group>
            </b-col>	
            <b-col cols="12">
                <b-form-group
                    label="Activity Description"
                    label-for="h-actiivity-name"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-textarea
                        id="h-actiivity-name"
                        rows="6"
                        max-rows="6"
                        v-model="form.description"
                        readonly
                    >
                    </b-form-textarea>
                </b-form-group>
            </b-col>									      
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group
                    label="Project Name"
                    label-for="h-project_name"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-input
                        id="h-project_name"
                        v-model="form.project_name"
                        readonly
                    />
                </b-form-group>
            </b-col>
            <template v-if="form.activity_type == 0">
                <b-col cols="12">
                    <b-form-group
                        label="Venue"
                        label-for="h-venue"
                        label-cols-xl="3"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="h-venue"
                            v-model="form.venue"
                            readonly
                        />
                    </b-form-group>
                </b-col>    
            </template>
            <template v-else>
                <b-col cols="12">
                    <b-form-group
                        label="Venue"
                        label-for="h-venue"
                        label-cols-xl="3"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="h-venue"
                            v-model="form.trained"
                            readonly
                        />
                    </b-form-group>
                </b-col>    
            </template>
            
            <b-col cols="12">
                <b-form-group
                    label="Start Date"
                    label-for="h-start_date"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <div class="calendarIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                    </div>
                    <b-form-input
                        id="h-start_date"   
                        class="fieldCalendar"                    
                        v-model = "form.commencement_date"
                        :config="{ altInput: true , altFormat: 'DD MMM YY', dateFormat: 'DD/MM/YY' }"
                        readonly
                    />
                </b-form-group>
            </b-col>			
            <b-col cols="12">
                <b-form-group
                    label="End Date"
                    label-for="h-end_date"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-input
                        id="h-end_date"
                        v-model="form.completion_date"
                        readonly
                    />
                </b-form-group>
            </b-col>			
            <b-col cols="12">
                <b-form-group
                    label="Activity Status"
                    label-for="h-status"
                    label-cols-xl="3"
                    label-cols-md="4"
                    >
                    <b-form-input v-if="form.status === '0'"
                        value="Not Done"
                        readonly
                    />
                    <b-form-input v-else-if="form.status === '1'"
                        value="In Progress"
                        readonly
                    />
                    <b-form-input v-else-if="form.status === '2'"
                        value="Done"
                        readonly
                    />
                    <b-form-input v-else
                        value="Terminated"
                        readonly
                    />
                </b-form-group>
            </b-col>									      
        </b-row>
    </b-form>
</template>

<script>
    const moment = require('moment')
	export default {
	  	components: {},
	  	data() {
		    return {
                form: {},
            }
		},
		created(){
            this.fetchActivity();
		},
		methods: {
            fetchActivity(){
                this.$store
                .dispatch('activities/fetchActivity', {
                    id: this.$route.params.id
                })
                .then(response => {

                    this.form = response.data.data;                   
                    this.form.commencement_date = this.form.commencement_date ? moment(this.form.commencement_date).format('DD/MM/YY') : null
                    this.form.completion_date = this.form.completion_date ? moment(this.form.completion_date).format('DD/MM/YY') : null

                    // console.log('basic info: ', response.data.data)
                    
                })
                .catch((error) => {
                    console.log(error)
                })
            },
		}
	};	

</script>

<style>    
    .tabs .tab-content .pt-4-panel.tab-pane{
        padding-top: 1.5rem
    }
    @media only screen and (min-width: 1441px){
        .tabs .tab-content .tab-pane{
            padding-right: 50px;
        }
    }    
    @media only screen and (max-width: 1440px){
        .tabs .tab-content .tab-pane{
            padding-right: 40px;
            padding-left: 40px;
        }
    }
    @media only screen and (max-width: 1326px){
        .tabs .tab-content .tab-pane{
            padding-right: 15px;
            padding-left: 15px;
        }
    }
</style>