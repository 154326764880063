<template>
	<b-card class="rad-container black-child size-16 pt-lg-1 pb-lg-2">
		<h2 class="mb-1" v-if="detail && detail.evaluation">{{ detail.evaluation.title }}</h2>
		<p>Presenter: {{ detail.presenter_name }} <span class="px-1">|</span> Date: {{ date_evaluation }}</p>
		<hr style="border-top: 1px solid #000; margin-top: 20px" />
		<p class="pb-1 mb-0"></p>

		<template v-if="this.$route.query.template == 1">

			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q1. The session was highly relevant.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_01"
					      plain
					      value="1"
					      v-model="quest_1"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_01"
					      plain
					      value="2"
					      v-model="quest_1"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_01"
					      plain
					      value="3"
					      v-model="quest_1"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_01"
					      plain
					      value="4"
					      v-model="quest_1"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_1">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q2. The coverage and depth were adequate.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_02"
					      plain
					      value="1"
					      v-model="quest_2"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_02"
					      plain
					      value="2"
					      v-model="quest_2"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_02"
					      plain
					      value="3"
					      v-model="quest_2"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_02"
					      plain
					      value="4"
					      v-model="quest_2"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_2">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q3. The duration and pace were just right to aid understanding of the subject matter.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_03"
					      plain
					      value="1"
					      v-model="quest_3"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_03"
					      plain
					      value="2"
					      v-model="quest_3"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_03"
					      plain
					      value="3"
					      v-model="quest_3"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_03"
					      plain
					      value="4"
					      v-model="quest_3"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_3">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q4. The presenter had good knowledge and understanding of the subject matter.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_04"
					      plain
					      value="1"
					      v-model="quest_4"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_04"
					      plain
					      value="2"
					      v-model="quest_4"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_04"
					      plain
					      value="3"
					      v-model="quest_4"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_04"
					      plain
					      value="4"
					      v-model="quest_4"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_4">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q5. The presenter was effective in communicating ideas and concepts.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_05"
					      plain
					      value="1"
					      v-model="quest_5"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_05"
					      plain
					      value="2"
					      v-model="quest_5"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_05"
					      plain
					      value="3"
					      v-model="quest_5"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_05"
					      plain
					      value="4"
					      v-model="quest_5"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_5">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q6. The presenter was able to respond effectively to questions from participants.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_06"
					      plain
					      value="1"
					      v-model="quest_6"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_06"
					      plain
					      value="2"
					      v-model="quest_6"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_06"
					      plain
					      value="3"
					      v-model="quest_6"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_06"
					      plain
					      value="4"
					      v-model="quest_6"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_6">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q7. The training enviornment was conducive for learning.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_07"
					      plain
					      value="1"
					      v-model="quest_7"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_07"
					      plain
					      value="2"
					      v-model="quest_7"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_07"
					      plain
					      value="3"
					      v-model="quest_7"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_07"
					      plain
					      value="4"
					      v-model="quest_7"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_7">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q8. The materials and handouts, including videos and presentation slides, were well organised and useful to aid learning.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_08"
					      plain
					      value="1"
					      v-model="quest_8"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_08"
					      plain
					      value="2"
					      v-model="quest_8"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_08"
					      plain
					      value="3"
					      v-model="quest_8"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_08"
					      plain
					      value="4"
					      v-model="quest_8"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_8">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 area star-required-radio" style="flex-wrap: wrap;">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q9. If you have indicated disagree or strong disagree for any of the questions above, do let us know your concerns/comments (if any) so as to help us further improve our future programmes.
				</b-col>
				<b-col lg="8" xl="7" sm="12">
					<b-form-textarea
						v-model="quest_textarea_1"
				      	placeholder=""
				      	rows="3"
				    />
				    <template v-if="require_show">
                      	<small class="text-danger" v-if="!quest_textarea_1">This field is required</small>
                    </template>
				</b-col>
			</div>
			<hr />
			<div class="text-center mb-2" style="padding-top: 10px">
	            <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
	            <b-button type="button" variant="primary" class="btn-df size-18 black" @click="submitAction">
	                Submit
	            </b-button>
	        </div>
	    </template>
	    <template v-else-if="this.$route.query.template == 2">

			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q1. Overall, the programme met its intended objective(s).
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_01"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_1"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_01"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_1"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_01"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_1"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_01"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_1"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_1">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q2. The duration and pace of the programme were just right to achieve the intended objective(s).
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_02"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_2"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_02"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_2"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_02"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_2"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_02"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_2"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_2">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q3. The programme was positioned at a suitable level for participants
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_03"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_3"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_03"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_3"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_03"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_3"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_03"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_3"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_3">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q4. The concepts, sharing and examples presented were useful and relveant.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_04"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_4"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_04"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_4"
					    >
					      Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_04"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_4"
					    >
					      Agree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_04"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_4"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_4">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q5. The programme staff were helpful and approachable.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_05"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_5"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_05"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_5"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_05"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_5"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_05"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_5"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_5">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q6. The class size was appropriate.
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_06"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_6"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_06"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_6"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_06"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_6"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_06"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_6"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_6">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q7. Overall, how satisfied are you with the programme?
				</b-col>
				<b-col lg="8" xl="7" sm="12" class="rad-list">
					<b-form-group>
						<b-form-radio
					      name="radios_07"
					      plain
					      text="Strongly Disagree"
					      value="1"
					      v-model="quest2_7"
					    >
					      Strongly Disagree
					    </b-form-radio>
					    <b-form-radio
					      name="radios_07"
					      plain
					      text="Disagree"
					      value="2"
					      v-model="quest2_7"
					    >
					      Disagree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_07"
					      plain
					      text="Agree"
					      value="3"
					      v-model="quest2_7"
					    >
					      Agree
					    </b-form-radio>
					     <b-form-radio
					      name="radios_07"
					      plain
					      text="Strongly Agree"
					      value="4"
					      v-model="quest2_7"
					    >
					      Strongly Agree
					    </b-form-radio>
					</b-form-group>
					<template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_7">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 area star-required-radio" style="flex-wrap: wrap; align-items: center">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q8. List one or more areas that you have to learnt from the programme that can be applied to enhance your persional effectiveness as a leader/trainer in your institute/organisation.
				</b-col>
				<b-col lg="8" xl="7" sm="12">
					<b-form-textarea
						v-model="quest2_textarea_1"
				      	placeholder=""
				      	rows="3"
				    />
				    <template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_textarea_1">This field is required</small>
                    </template>
				</b-col>	
			</div>
			<hr />
			<div class="item d-flex mb-2 area star-required-radio" style="flex-wrap: wrap;">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q9. Would you like to provide us with a testimonial for this programme?
				</b-col>
				<b-col lg="8" xl="7" sm="12">
					<b-form-textarea
						v-model="quest2_textarea_2"
				      	placeholder=""
				      	rows="3"
				    />
				    <template v-if="require_show">
                      	<small class="text-danger" v-if="!quest2_textarea_2">This field is required</small>
                    </template>
				</b-col>
			</div>
			<hr />
			<div class="item d-flex mb-2 area star-required-radio" style="flex-wrap: wrap;">
				<b-col lg="4" xl="5" sm="12" class="mb-lg-0 mb-1 col-form-label">
					Q10. Any other comments.
				</b-col>
				<b-col lg="8" xl="7" sm="12">
					<b-form-textarea
						v-model="quest2_textarea_3"
				      	placeholder=""
				      	rows="3"
				    />
				    <template v-if="require_show">
	                  	<small class="text-danger" v-if="!quest2_textarea_3">This field is required</small>
	                </template>
				</b-col>
			</div>
			<hr />
			<div class="text-center mb-2" style="padding-top: 10px">
	            <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
	            <b-button type="button" variant="primary" class="btn-df size-18 black" @click="submitAction_2">
	                Submit
	            </b-button>
	        </div>
	    </template>

	</b-card>
</template>
<script>
	const moment = require('moment');
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	export default {
		components: {
	  		ToastificationContent
	  	},
	  	data() {
		    return {
		    	detail: [],
		    	quest_1: null,
		    	quest_2: null,
		    	quest_3: null,
		    	quest_4: null,
		    	quest_5: null,
		    	quest_6: null,
		    	quest_7: null,
		    	quest_8: null,
		    	quest_textarea_1: null,

		    	quest2_1: null,
		    	quest2_2: null,
		    	quest2_3: null,
		    	quest2_4: null,
		    	quest2_5: null,
		    	quest2_6: null,
		    	quest2_7: null,
		    	quest2_textarea_1: null,
		    	quest2_textarea_2: null,
		    	quest2_textarea_3: null,

		    	require_show: false,
		    	date_evaluation: null,	
		    }
		},
		created(){
			if(this.$route.query.template == 1){
				this.fetchItemTemplate()
			}
			if(this.$route.query.template == 2){
				this.fetchItemTemplate_2()
			}	
			// console.log('this.$route 222: ', this.$route)
		},
		methods: {
			fetchItemTemplate(){
				this.$store
				.dispatch('activities/eval_template_detail', {
					id: this.$route.params.evalution_id	
				})
				.then(response => {
					this.detail = response.data.data
					if(this.detail && this.detail.evaluation){
						this.date_evaluation = moment(this.detail.evaluation.date).format('DD/MM/YYYY') 
					}	
					if(this.detail){
						this.quest_1 = this.detail.content.question1
						this.quest_2 = this.detail.content.question2
						this.quest_3 = this.detail.content.question3
						this.quest_4 = this.detail.content.question4
						this.quest_5 = this.detail.content.question5
						this.quest_6 = this.detail.content.question6
						this.quest_7 = this.detail.content.question7
						this.quest_8 = this.detail.content.question8
						this.quest_textarea_1 = this.detail.content.question9
					}
					// console.log('Template 1 detail: ', response.data.data)	
				})
				.catch((error) => {
					console.log(error)
				})
			},
			fetchItemTemplate_2(){
				this.$store
				.dispatch('activities/eval_template_detail', {
					id: this.$route.params.evalution_id	
				})
				.then(response => {
					this.detail = response.data.data
					if(this.detail && this.detail.evaluation){
						this.date_evaluation = moment(this.detail.evaluation.date).format('DD/MM/YYYY') 
					}	
					if(this.detail){						
						this.quest2_1 = this.detail.content.question1
						this.quest2_2 = this.detail.content.question2
						this.quest2_3 = this.detail.content.question3
						this.quest2_4 = this.detail.content.question4
						this.quest2_5 = this.detail.content.question5
						this.quest2_6 = this.detail.content.question6
						this.quest2_7 = this.detail.content.question7
						this.quest2_textarea_1 = this.detail.content.question8
						this.quest2_textarea_2 = this.detail.content.question9
						this.quest2_textarea_3 = this.detail.content.question10
					}
					// console.log('Template 2 detail: ', response.data.data)	
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAction(){
				this.require_show = false
				// this.$router.push({ path: `/participant/my-activities/${this.$route.params.id}`, query: { tab: 3 } })
				window.close()
			},
			submitAction(){
				if(this.quest_1 && this.quest_2 && this.quest_3 && this.quest_4 && this.quest_5 && this.quest_6 && this.quest_7 && this.quest_8 && this.quest_textarea_1 ){
					
					this.createSubmit()
				}
				else{
					this.require_show = true
				}
			},
			submitAction_2(){
				if(this.quest2_1 && this.quest2_2 && this.quest2_3 && this.quest2_4 && this.quest2_5 && this.quest2_6 && this.quest2_7 && this.quest2_textarea_1 && this.quest2_textarea_2 && this.quest2_textarea_3 ){
					
					this.createSubmit()
				}
				else{
					this.require_show = true
				}
			},
			createSubmit(){
				let datas = {}
				if(this.$route.query.template == 1){
					datas = {
						activity_id: this.$route.params.id,
						evaluation_id: this.$route.params.evalution_id,
						question1: this.quest_1,
						question2: this.quest_2,
						question3: this.quest_3,
						question4: this.quest_4,
						question5: this.quest_5,
						question6: this.quest_6,
						question7: this.quest_7,
						question8: this.quest_8,
						question9: this.quest_textarea_1
					}
				}
				else{
					datas = {
						activity_id: this.$route.params.id,
						evaluation_id: this.$route.params.evalution_id,
						question1: this.quest2_1,
						question2: this.quest2_2,
						question3: this.quest2_3,
						question4: this.quest2_4,
						question5: this.quest2_5,
						question6: this.quest2_6,
						question7: this.quest2_7,
						question8: this.quest2_textarea_1,
						question9: this.quest2_textarea_2,
						question10: this.quest2_textarea_3,
					}
				}				
				// console.log('datas: ', datas)
				this.$store
				.dispatch('activities/submitEvatemplate', datas)
				.then(response => {
					this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.detail.evaluation.title + ' Updated!',
                          variant: 'success',
                        },
                    },
                    {
                      	position: 'top-center'
                    })
                    this.require_show = false
					this.$router.push({ path: `/participant/my-activities/${this.$route.params.id}`, query: { tab: 3 } })
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			}
		}
	};	

</script>

<style lang="css">
	.rad-list{
		justify-content: space-between;
	}
	.rad-list input[type="radio"]{
		position: relative;
		margin: 10px auto 0;
		display: block; 
	}
	.rad-list .form-check{
		display: flex;
    	flex-direction: column-reverse;
	}
	@media screen and (min-width: 1401px) {
		.rad-container .col-xl-5{
			flex: 0 0 45%;
    		max-width: 45%;
		}
		.rad-container .col-xl-7{
			flex: 0 0 55%;
    		max-width: 55%;
		}
		.rad-container .area .col-xl-5{
			flex: 0 0 46%;
    		max-width: 46%;
		}
		.rad-container .area .col-xl-7{
			flex: 0 0 54%;
    		max-width: 54%;
		}
	}
</style>
