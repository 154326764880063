<template>
    <div>
		<b-table small :fields="fields_attendance" :items="attendance" responsive="lg" tbody-tr-class="attendance-row size-14" v-if="!this.form.codeID" show-empty>

			<template #cell(no)="data">
				{{ data.index + 1 }}
			</template>
			<template #cell(remarks)="data">
				<span v-if="data.item.remarks" class="text-center maxThreeRow">{{ data.item.remarks }}</span>
				<span v-else="" class="text-center d-block">-</span>
			</template>
			<template #cell(status_check_in)="data">
				<!-- -- {{ data.item.status_check_in }} -->
				<span class="text-center d-block red-ico" v-if="data.item.status_check_in === 0">-</span>
				<span class="text-center d-block blue-ico" v-else-if="data.item.status_check_in === 1">
					<feather-icon
						icon="CheckIcon"
						size="20"
						/>
				</span>
				<span class="text-center d-block red-ico" v-else>
					<feather-icon
						icon="XIcon"
						size="20"
						/>
				</span>
			</template>
			<template #cell(timeslot)="data">
				<span class="text-center d-block">{{ data.item.from | formatDatebyMoment("hh:mm", "hh:mm A") }} - {{ data.item.to | formatDatebyMoment("hh:mm", "hh:mm A") }}</span>
			</template>
			<template #cell(actions)="data">
				<template v-if="data.item.status_check_in === 0 && data.item.status === 1">
					<div class="text-center d-block black-ico cursor-pointer" @click="savecodeID(data.item.otp_code, data.item.id)" style="color: #8471FF">
						Check-in
					</div>
				</template>
				<template v-else>
					<div class="text-center d-block" style="opacity: .3">
						Check-in
					</div>
				</template>	
			</template>
			<template #cell(date)="data">
	        	<span class="text-center d-block">{{ data.item.date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
	      	</template>
			<template #cell()="data">
				{{ data.value }}
			</template>
			<template #empty="scope">
		      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
		    </template>

		</b-table>
		<div id="checkin-frm" v-else-if="this.form.codeID != null">
			<template>
				<div class="size-24 pl-2"><strong>Attendance - Check-in Screen</strong></div>
				<div class="line"></div>
				<b-form v-if="show" class="text-center">

					<div class="size-24 medium black">
						<p>Session</p>
						<p>Enter Code</p>
					</div>
					<b-form-group label-for="input-code" style="display: none">
						<b-form-input
							v-model="form.codeID"
							placeholder="Code"
						></b-form-input>
					</b-form-group>	
					<b-form-group label-for="input-code">
						<b-form-input
							v-model="form.code"
							placeholder="Code"
						></b-form-input>
						<template v-if="error_mess">
							<small v-if="!form.code" class="text-danger" style="display: block; text-align: left; margin-top: 10px ">The Code field is required</small>
						</template>
						<template v-if="(!form.code) || (form.code && form.code == form.codeID)">
							<div style="height: 70px"></div>
						</template>
						<template v-else-if="form.code && form.code != form.codeID">
							<small class="text-danger" style="padding: 3rem 0 1rem; display: block;">Code is incorrect, please try again.</small>
						</template>
					</b-form-group>									     

					<span class="size-18 d-block" style="color: #B1B1B1; margin-bottom: 30px">Status: Not Checked in yet</span>

					<b-button type="button" variant="basic" @click="onBack" class="k-mx-5" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); ">Back</b-button>
					<b-button @click="checkinAction" variant="primary" class="k-mx-5">Verify</b-button>									      	
				</b-form>
			</template>
		</div>
	</div>
</template>

<script>
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	export default {
	  	data() {
		    return {
                fields_attendance:[
		    		{ key: 'no', label: 'Session' },
		          	{ key: 'date', label: 'Date' },
		          	{ key: 'timeslot', label: 'Timeslot' },
		          	{ key: 'remarks', label: 'Remarks' },
		          	{ key: 'status_check_in', label: 'Status' },
		          	{ key: 'actions', label: 'Actions' },
			    ],
			    attendance:[],
			    form: {
		          	code: null,
		          	codeID: null
		        },
		        show: true,
		        id_tam: null,
		        error_mess: false,
		        get_OTP_from: null
            }
		},
		created(){
			this.form.codeID = null
			this.fetchAttendance()
			if(this.$route.query.checkIn){
				this.getOtpfromDashboard()
			}		
		},
		methods: {         
			fetchAttendance(){
                this.$store.dispatch('activities/fetchAttendance', {
					activity_id: this.$route.params.id
                })
                .then(response => {
                    this.attendance = response.data.data;
                    // console.log('Attendance: ', response.data.data)
                })
                .catch((error) => {
                    console.log(error)
                })
            },   
	      	savecodeID(item, id){      		
	      		this.form.codeID = item
	      		this.id_tam = id
	      	},
	      	onBack(){
	      		this.form.codeID = null
	      		this.get_OTP_from = null
	      		this.id_tam = null
	      	},
	      	checkinAction() {
	      		if(this.form.code){
			        let info = {
						code: this.form.code,
						timeline_id: this.id_tam
					}
					// console.log('data: ', info)
					this.$store
					.dispatch('activities/checkInOTP', info)
					.then(response => {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'Check-in Successful',
	                          variant: 'success',
	                        },
	                    },
	                    {
	                      position: 'top-center'
	                    })
	                    this.form.codeID = null; 
			        	this.id_tam = null
			        	this.error_mess = false
			        	this.$router.push({ path: `/participant/my-activities/${this.$route.params.id}` })
			        	this.fetchAttendance()
					})
					.catch((error) => {
						console.log(error)
						this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                        	title: error.response.data.message,
	                        	variant: 'danger',
	                      	},
	                    },
	                    {
	                      position: 'top-center'
	                    })
					})
				}
				else{
					this.error_mess = true
				}
	      	},	      	
	      	getOtpfromDashboard(){
				this.$store
				.dispatch('project/timelineCheckinDashboard', {})
				.then(response => {

					this.get_OTP_from = response.data.data.timeline_checkin
					console.log('this.get_OTP_from: ', this.get_OTP_from)

					if(this.get_OTP_from != null && this.get_OTP_from.otp_code ) {
						this.form.codeID = this.get_OTP_from.otp_code
						this.id_tam = this.get_OTP_from.id
					}					
				})
				.catch((error) => {
					console.log(error)
				})
			},
		}
	};	

</script>

<style lang="scss">
</style>