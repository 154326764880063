<template>
	<section id="portfolio">
		<b-row>
		    <b-col md="12">
				<Breadcrumb :labels="labels" :detailName="detailName" class="mb-2" />
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">  
		    	<template v-if="this.$route.name == 'my-activities-eva-template'">
		    		<EvalutionSingle />
		    	</template>
				<template v-else>
					<b-tabs card v-model="tabIndex">
				      	<b-tab title="Basic Info" class="basic-tab pt-4-panel">
				        	<div class="content-tab info-content">
				        		<BasicInfo/>
				        	</div>
				     	</b-tab>
				      	<b-tab title="Documents" class="document-tab card-py-0 w-30-percent-cl-2 w-25-percent-cl-3">
							<Documents/>
				      	</b-tab>
				      	<b-tab title="Attendance" class="attendance-tab card-py-0 w-15-percent-cl-2 w-20-percent-cl-3 w-25-percent-cl-4">
						    <Attendance/>
				     	</b-tab>
				      	<b-tab title="Evaluation" class="card-py-0 w-25-percent-cl-2 w-20-percent-cl-3">
				        	<Evaluation/>
				      	</b-tab>				      	
					</b-tabs>
				</template>
		    </b-col>
		</b-row>
	</section>
</template>
<script>
	import BasicInfo from './BasicInfo';
	import Documents from './Documents';
	import Attendance from './Attendance';
	import Evaluation from './Evaluation';
	import EvalutionSingle from './EvalutionSingle';
	import Breadcrumb from '../../partial/Breadcrumb'
	export default {
	  	components: {
			BasicInfo,
			Documents,
			Attendance,
			Evaluation,
			EvalutionSingle,
			Breadcrumb
		},
	  	data() {
		    return {
		    	activity_single: {},
		    	tabIndex: 0,
		    	labels: { "activities-detail": "" },
		    	detailName: {},
		    }
		},
		watch: {
			"$route": function(val) {
				if(val){
					if(val.name == 'my-activities-eva-template'){
						this.detailName = { "my-activities-eva-template": "" }
						this.getTemplateName()
					}
					this.fetchActivity()
					if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)
				}
			}
		},	
		created(){
			this.fetchActivity()

			if(this.$route.name && this.$route.name == 'my-activities-eva-template' ){
				this.detailName = { "my-activities-eva-template": "" }
				this.getTemplateName()
			}
			if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)

			// console.log('this.$route 333: ', this.$route)
		},
		methods: {
			fetchActivity(){
				this.$store
				.dispatch('activities/fetchActivity', {
					id: this.$route.params.id	
				})
				.then(response => {
					this.activity_single = response.data.data;		
					this.labels['activities-detail'] = response.data.data.name	
					// console.log('this.activity_single: ', this.activity_single)			
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getTemplateName(){
				this.$store
				.dispatch('activities/eval_template_detail', {
					id: this.$route.params.evalution_id	
				})
				.then(response => {
					this.detailName['my-activities-eva-template'] = response.data.data.evaluation.title
				})
				.catch((error) => {
					console.log(error)
				})
			},
		}
	};	

</script>

<style lang="css">
	.red-ico svg{
		color: #DD3E47; 
	}
	.blue-ico svg{
		color: #8471FF; 
	}
	.black-color, .black-color-child *{
		color: #000000; 
	}
	h3{
		color: #8471FF;
	}	
	.medium{
		font-weight: 500;
	}
	.tabs table{
		color: #000000CC; 
	}
	.tabs .card-header{
		padding: 0; 
		background-color: transparent; 
		border-bottom: 0; 
	}
	.tabs .nav-tabs{
		margin-left: 0;
		margin-right: 0; 
	}
	.tabs .nav-tabs li:not(:last-child){
		margin-right: 10px;
	}
	.tabs .nav-tabs li{
		margin-bottom: 20px;
	}
	.tabs .nav-tabs li a:after{
		content: none  !important; 
	}
	.tabs .nav-tabs li a{
		background-color: #fff; 
		border-radius: 5px;
		font-size: 18px;
		padding: 11px 14px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		min-width: 150px;
    	height: 50px
	}
	.tabs .nav-tabs li a.active{
		background-color: #8471FF; 
		color: #fff; 		
	}
	.tabs .tab-content{
		background-color: #fff; 
		margin-top: 30px;
	}
	thead tr th:not(:first-child){
		text-align: center !important; 
	}
	thead tr th *{
		text-transform: capitalize;
	}
	thead tr th{
		font-size: 16px !important; 
	}
	thead tr th, tbody tr td{
		padding: 7px 20px !important;
	}
	thead tr th{
		font-weight: 500;
	}
	.tab-pane:not(.basic-tab){
		padding-left: 0 !important;
    	padding-right: 0 !important;
	}
	.black-ico svg{
		color: #000; 
	}
	.cursor-pointer{
		cursor: pointer; 
	}
	.attendance-tab .card{
		box-shadow: none;
	    margin-bottom: 0;
	    line-height: 1;
	}
	.attendance-tab .black-ico{
		text-decoration: underline;
	}
	.attendance-tab .black-ico:hover{
		text-decoration: none;
	}
	.attendance-tab .attendance-row:hover > td:not(:first-child) *{
		color: #8471FF !important; 
	}
	.evaluation-tab:hover *{
		color: #8471FF; 
	}
	.evaluation-tab td{
		text-align: center; 
	}
	.evaluation-tab:hover td svg path{
		fill: #8471FF
	}	
	@media screen and (max-width: 1400px) {
		.tabs .nav-tabs li a{
			font-size: 16px;
		}
		.tabs .tab-content{
			margin-top: 20px;
		}
		.tabs .nav-tabs li a{
	        min-width: 145px;
	        height: 45px;
	    }
	}
</style>

