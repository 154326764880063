<template>
    <ul class="breadcrumb size-24 mb-0 pl-0">
        <template v-for="(routeObject, index) in routers">
            <template v-if="isLast(index)">
                <template v-if="getNameDetail(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_namedetail(routeObject)">
                        {{ getNameDetail(routeObject) }}
                    </li>
                </template>      
                <li class="breadcrumb-item medium">
                    <span style="color: #000000CC">{{ getName(routeObject) }}</span>
                </li>
            </template> 
            <li class="breadcrumb-item" v-else><router-link style="color: #000000CC" :to="getPath(routeObject.path)">{{ getName(routeObject) }}</router-link></li>            
        </template>
    </ul>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: false,
                default: () => ''
            },
            labels: {
                type: Object,
                required: false,
                default: () => {}
            },
            detailName: {
                type: Object,
                required: false,
                default: () => {}
            },
            name_sublabel: {
                type: Object,
                required: false,
                default: () => {}
            },
        },
        data() {
            return {}
        },
        computed: {
            routers() {
                let list = this.$route.matched.filter((route) => (route.meta.label));

                if(this.label && list[list.length - 1]) list[list.length - 1].meta.label = this.label;
                if(Object.getOwnPropertyNames(list).length) {
                    list.forEach(item => {
                        if(item.path.indexOf(':id') >= 0) {
                            item.path = item.path.replace(":id", this.$route.params.id)
                        }
                        if(this.labels && this.labels[item.name]) {
                            item.meta.label = this.labels[item.name];
                        }  
                        if( this.name_sublabel && this.name_sublabel[item.name] ) {
                            item.meta.namedetail = this.name_sublabel[item.name];
                        }  
                        if( this.detailName && this.detailName[item.name] ) {
                            item.meta.label = this.detailName[item.name]
                        }                 
                    });
                }
                return list;
            },
        },
        methods: {
            getPath(val) {
                let path = val.split("/")
                if(path.length >= 3 && path[2] != undefined && path[2].includes(":")) {
                    path[2] = this.$route.params.id;
                }

                if(this.$route.name == 'my-activities-eva-template' && path.length == 4 )
                {
                    path[3] = this.$route.params.id;
                }

                // console.log('route new: ', this.$route)
                // console.log('path', path)
                // console.log('path.length', path.length)

                val = path.join('/');
                return val
            },
            getName(item) {
                return item.meta && item.meta.label ? item.meta.label : null
            },
            getNameDetail(item){         
                return item.meta && item.meta.namedetail ? item.meta.namedetail : null
            },
            click_namedetail(item){
                let link = null

                if(item.meta && item.meta.namedetail){

                    link = `${this.$route.matched[0].path}/${this.$route.params.id}`
                }
                if(item.meta.tabIndex != null){
                    this.$router.push({ path: link, query: { tab: item.meta.tabIndex, child: item.meta.child_tab_01  } })
                } else this.$router.push({ name: item.meta.navActiveLink })
            },
            isLast (index) {
                return index === this.routers.length - 1
            },
            isLast1 (index) { 
                if(this.routers.meta && this.routers.meta.sublabel)
                return index === this.routers.length
            },      
        }
    };  

</script>